<template>
    <div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="80%" center>
            <div class="d-flex mt20 mb20">
                <div>
                    <el-input class="w400 mr10" placeholder="输入企业名称关键字搜索" v-model="searchData.name" @change="searchFun"/>
                    <!-- <el-button type="primary" @click="searchFun">搜索</el-button> -->
                </div>
            </div>
            <el-table v-loading="loading" class="tableRadio" ref="multipleTable" :data="tableData" border lay
                tooltip-effect="dark" @current-change="selectData" :highlight-current-row="true">
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="companyName" align="center" label="企业名称" show-overflow-tooltip />
                <el-table-column prop="companyTypeName" align="center" label="企业类型" show-overflow-tooltip />
                <el-table-column prop="legalName" align="center" label="法人代表" show-overflow-tooltip />
                <el-table-column prop="printName" align="center" label="打印人" show-overflow-tooltip />
                <el-table-column prop="fileDay" align="center" label="制度日期" show-overflow-tooltip />
                <el-table-column prop="startTime" align="center" label="成立时间" show-overflow-tooltip />
            </el-table>
            <div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="tableData.length !== 0">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
                    class="" :total="total" :pager-count="5" />
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { getProjectCompanyList } from "@/api/projectAdmin";
export default {
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            //模态框配置
            modelTitle: "点击选择研发企业",
            btnLoading: false,
            checkedData: {},
        };
    },
    filters: {

    },
    created() {

    },
    methods: {
        showModelFun() {
            this.showModel = true;
            //获取研发公司列表
            this.getList();
        },
        hideModelFun() {
            this.showModel = false;
        },
        selectData(data) {
            this.checkedData = data;
            this.$emit("confirmFun", this.checkedData);
            this.hideModelFun();
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                companyName: this.searchData.name
            };
            try {
                this.loading = true;
                let result = await getProjectCompanyList(params)
                const { data } = result;
                this.loading = false;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>